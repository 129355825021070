var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":10,"search":_vm.search,"header-props":{ sortIcon: null },"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"page-title"},[_c('v-icon',{staticClass:"secondary--text",attrs:{"left":"","light":""}},[_vm._v(_vm._s(_vm.icons.mdiCogClockwise))]),_vm._v(" "+_vm._s(_vm.$route.meta.title))],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-layout',[_c('v-spacer')],1)],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-text-field',{staticClass:"mx-2",attrs:{"color":"accent","append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"success","dark":"","fab":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.restoredUser(item.user_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.icons.mdiRestore))])],1)]}}],null,true)},[_c('span',[_vm._v("Restore")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"error","dark":"","fab":"","x-small":""},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.icons.mdiDeleteForeverOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Permanently")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.getUsers}},[_vm._v(" Reset ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }