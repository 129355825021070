<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiCogClockwise }}</v-icon>
            {{ $route.meta.title }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-layout>
            <v-spacer></v-spacer>
          </v-layout>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              v-bind="attrs"
              v-on="on"
              @click.prevent="restoredUser(item.user_id)"
              dark
              class="mx-1"
              fab
              x-small
            >
              <v-icon size="20">{{ icons.mdiRestore }}</v-icon>
            </v-btn>
          </template>
          <span>Restore</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on" @click.prevent="" dark class="mx-1" fab x-small>
              <v-icon size="20">{{ icons.mdiDeleteForeverOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Delete Permanently</span>
        </v-tooltip>
      </template>
      <!-- <template v-slot:body="{ items }">
        <tbody>
          <tr></tr>
        </tbody>
      </template> -->
      <template v-slot:no-data>
        <v-btn color="accent" @click="getUsers"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiAccountCircle,
  mdiAccountKeyOutline,
  mdiCogClockwise,
  mdiRestore,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {},

  created: function () {
    this.getUsers()
  },

  data() {
    return {
      users: [],

      headers: [
        {
          text: 'First Name',
          value: 'first_name',
        },
        {
          text: 'Last Name',
          value: 'last_name',
        },
        {
          text: 'User Type',
          value: 'user_type',
        },
        {
          text: 'Email Address',
          value: 'email',
        },
        { text: 'Created At', value: 'created_at', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: '',
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiAccountCircle,
        mdiAccountKeyOutline,
        mdiCogClockwise,
        mdiRestore,
      },
    }
  },
  methods: {
    getUsers: function () {
      this.$store
        .dispatch('user/deletedUserPermission')
        .then(response => {
          this.users = this.fetchUsers
        })
        .catch(err => {
          console.log(err)
        })
    },
    restoredUser: function (id) {
      this.$swal
        .fire({
          title: 'Are you sure you want to restore this?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Restored!',
              text: 'The record has been restored.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })

            this.$store
              .dispatch(`user/restoredeletedUser`, { user_id: id, data: this.user })
              .then(response => {
                this.getUsers()
              })

              .catch(err => {
                console.log(err)
              })
          }
        })
    },
    formatDate(value) {
      return moment(value).format('l')
    },
  },
  computed: {
    ...mapGetters({
      fetchUsers: 'user/fetchUsers',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
  },
}
</script>

<style>
</style>